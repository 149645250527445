<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" page_title="inbox" />

    <v-container fluid>
      <!-- New UI -->
      <v-card class="rounded-xl pa-6">
        <v-row v-if="loading">
          <v-col cols="6" v-for="n in 6" :key="n">
            <v-skeleton-loader
              class="mx-auto"
              max-height="120"
              type="image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <template>
            <v-col
              cols="12"
              lg="6"
              sm="12"
              v-for="(item, index) in inboxData"
              :key="index"
              :items="inboxData"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
            >
              <v-card
                class="rounded-lg inboxcard"
                :search="search"
                :style="[{ 'background-color': item.frameContainer }]"
                @click="watchInboxNotiData(item, item.id)"
              >
                <v-card-title
                  v-model="searchtitle"
                  style="height: 100px; align-items: start"
                >
                  <span
                    style="font-size: 20px; color: #4d4f5c; cursor: pointer"
                    class="ml-0 truncated-text-2line"
                    >{{ item.question }}</span
                  >
                  <v-spacer></v-spacer>
                  <span style="font-size: 18px; color: #a6cc39">
                    {{ item.sendDate }}
                  </span>
                </v-card-title>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-card>
    </v-container>

    <soundPlay
      ref="soundPlay"
      :show="showSound"
      @close="showSound = false"
      :audioName="audioName"
      @show_sound="show_sound"
      @updateNotiReadStatusColor="getInboxNotificationData"
      :audioDescription="audioDescription"
      :dialog="dialog"
      :audioFile="contentFile"
      :viewerFlag="viewerFlag"
      :audioImg="audioImg"
      :contentid="contentId"
      :requestflag="requestflag"
    ></soundPlay>
    <videoPlay
      ref="videoPlay"
      :show="showVideoDialog"
      @close="showVideoDialog = false"
      @updateNotiReadStatusColor="getInboxNotificationData"
      :Addfav="AddVideofav"
      :videoName="videoName"
      :videoPlayerDialog="videoPlayerDialog"
      :videoFile="video_File"
      :contentid="contentId"
      :courseID="courseID"
      :viewerFlag="viewerFlag"
      :memberID="memberID"
      :video_page="video_page"
    ></videoPlay>
    <videoPlay
      ref="articleView"
      :show="showArticleDialog"
      @close="showArticleDialog = false"
      @updateNotiReadStatusColor="getInboxNotificationData"
      :Addfav="AddVideofav"
      :videoName="videoName"
      :videoPlayerDialog="videoPlayerDialog"
      :videoFile="video_File"
      :contentid="contentId"
      :courseID="courseID"
      :viewerFlag="viewerFlag"
      :memberID="memberID"
    ></videoPlay>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import soundPlay from "../Library/_subLibrary/_path/_sound/soundPlay.vue";
import videoPlay from "../Library/_subLibrary/_path/_video/videoPlay.vue";

export default {
  components: {
    soundPlay,
    videoPlay,
    //articleView
  },
  data: () => ({
    search: "",
    groupCopy: [],
    searchtype: null,
    searchtitle: null,
    searchuser: null,
    showFilterTools: false,
    noti_count: 0,
    dpSDate: "",
    dpEDate: "",
    dateoffset: new Date().getTimezoneOffset(),
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    breadcrumbs: [
      {
        sidebar_tilte: "home",
      },
      {
        text: "inbox",
      },
    ],
    startdate: "", //new Date().toISOString().substr(0, 10),
    enddate: "", //new Date().toISOString().substr(0, 10),
    startmenu: false,
    endmenu: false,
    inboxData: [],
    articledata: {
      contentName: "",
      contentFile: "",
      wordDescription: "",
      optimizeLink: "",
      link: "",
      pdfUrl: "",
      // favFlag:'',
      isshowarticleView: false,
    },
    articleView: [],
    //video dialog data
    videoPlay: [],
    AddVideofav: false,
    videoDescription: "",
    videoName: "",
    //contentId: 0,
    video_File: "",
    courseID: 0,
    //...................
    videoPlayerDialog: false,
    showVideoDialog: false,
    showArticleDialog: false,
    //Sound Data
    showSound: false,
    Addfav: false,
    audioDescription: "",
    audioName: "",
    audioImg: "",
    dialog: false,
    contentFile: "",
    contentId: 0,
    favFlag: "",
    soundPlay: [],
    requestflag: "",
    bannermaxlength: 90,
    viewerFlag: false,
    memberID: "",
    video_page: 0,
  }),

  mounted() {
    this.getInboxNotificationData();
    this.getFavourite();
  },

  methods: {
    // bannercutText(v) {
    //   if (v.length <= this.bannermaxlength) {
    //     return v;
    //   } else {
    //     return v.substring(0, this.bannermaxlength) + " ...";
    //   }
    // },
    show_sound() {
      this.showSound = true;
    },
    async watchInboxNotiData(item, itemid) {
      console.log("itemwatchInboxNotiData", item);
      console.log("itemwatchInboxNotiDatacontentType", item.contentType);
      console.log("itemid", itemid);
      let self = this;
      switch (item.contentType) {
        case 1:
          if (item.newNoti) {
            self.$store.commit("savenotificationTransactionIDForEvent", 0);
          } else {
            self.$store.commit(
              "savenotificationTransactionIDForEvent",
              item.notificationTransactionID
            );
          }

          self.$router.push({
            path: "/event/" + item.courseName.split("?").join(""),
            query: { course_id: item.id, eventName: item.courseName },
          });
          break;
        case 2:
          self.QnaNoti(item.id);
          self.$router.push({
            path: "/community",
            query: { id: itemid, name: "Inbox" },
          });
          break;
        case 3:
          //lesson
          if (item.newNoti) {
            self.$store.commit("savenotificationTransactionIDForLesson", 0);
          } else {
            self.$store.commit(
              "savenotificationTransactionIDForLesson",
              item.notificationTransactionID
            );
          }

          self.$router.push({
            path: "/LessonLearn/" + item.caseTitle.split("?").join(""),
            query: { lessonlearn_id: item.id },
          });
          break;
        case 5:
          if (item.newNoti) {
            console.log("item.newNoti", item.newNoti);
            self.$store.commit("savenotificationTransactionIDForArticle", 0);
          } else {
            self.$store.commit(
              "savenotificationTransactionIDForArticle",
              item.notificationTransactionID
            );
          }

          self.$router.push({
            path: "/Library/_subLibrary/_path/_article/articleView",
            query: { article_id: item.id },
          });
          break;
        case 6:
          if (item.newNoti === false) {
            self.$refs.soundPlay.Readnoti(item.notificationTransactionID, 2);
          }
          await self.$axios
            .get(
              `${this.web_url}Video/GetSoundByID?id=` +
                item.id +
                `&UserID=` +
                parseInt(localStorage.getItem("UserID"))
            )
            .then(function (res) {
              console.log("res.sound", res.data.data);
              if (res.data.status == 0) {
                self.soundPlay = res.data.data;
                const List = self.soundPlay.find((item) => item.id === itemid);
                self.showSound = true;
                self.audioImg = List.pictureBackground;
                self.audioName = List.content_Name;
                self.viewerFlag = List.viewerFlag;
                self.audioDescription = List.wordDescription;
                self.contentId = List.content_ID;
                self.dialog = true;
                self.contentFile = List.content_File;
                self.requestflag = List.requestFlag;
                self.$refs.soundPlay.getFavorite(List.favFlag);
                self.$refs.soundPlay.GetCommentsForSound(List.content_ID);
              }
            });
          break;
        case 7:
          console.log("item.newNoti", item.newNoti);
          if (item.newNoti === false) {
            self.$refs.videoPlay.Readnoti(item.notificationTransactionID, 3);
          }
          self.$axios
            .get(
              `${self.web_url}GuidedLesson/GetVideoByID?id=` +
                item.id +
                `&UserID=` +
                parseInt(localStorage.getItem("UserID"))
            )
            .then(function (response) {
              console.log("responsedatadata", response.data.data);
              if (response.data.status == 0) {
                self.videoPlay = response.data.data;

                const video = self.videoPlay.find((item) => item.id === itemid);
                self.showVideoDialog = true;
                self.AddVideofav = video.favFlag;
                self.video_File = video.content_File;
                self.contentId = video.content_ID;
                self.videoName = video.content_Name;
                self.courseID = -1; //distinguish from course
                self.viewerFlag = video.viewerFlag;
                self.memberID = video.memberID;
                self.video_page = 1;
                self.$refs.videoPlay.GetCommentsForVideo(video.content_ID);
                self.$refs.videoPlay.getFavorite(video.favFlag);
              }
            });

          break;
        case 8:
          //assignment
          self.ReadNoti(item.notificationID, 8);
          self.$router.push("/course");
          break;
        case 9:
          //quiz
          self.ReadNoti(item.notificationID, 9);
          self.$router.push("/course");
          break;
        case 10:
          self.ReadNotiForVideoComment(item);
          break;
        case 11:
          self.ReadNotiForCourseComment(item);
          break;
        case 12:
          self.ReadNotiForArticleComment(item);
          break;
        case 13:
          self.ReadNotiForSoundComment(item);
          break;
        case 14:
          self.ReadNotiForKnowledgeComment(item);
          break;
        case 15:
          self.ReadNotiForCourseComment(item);
          break;
        default:
          //question
          break;
      }
    },
    ReadNotiForCourseComment(item) {
      let self = this;
      //notificationTransactionID
      axios
        .post(
          `${self.web_url}NotificationTransaction/UpdateNotiForComment?id=` +
            item.notificationTransactionID
        )
        .then(function (res) {
          if (res.data.status == 0) {
            self.$router.push("/course");
          }
        });
    },
    ReadNotiForVideoComment(item) {
      let self = this;
      const request = {
        id: item.notificationTransactionID,
        videoID: item.id,
        userID: parseInt(localStorage.getItem("UserID")),
      };
      axios
        .post(
          `${self.web_url}NotificationTransaction/UpdateNotiForVideoComment`,
          request
        )
        .then(function (res) {
          if (res.data.status == 0) {
            self.videoPlay = res.data.data;

            const video = self.videoPlay.find((item) => item.id === item.id);
            self.showVideoDialog = true;
            self.AddVideofav = video.favFlag;
            self.video_File = video.content_File;
            self.contentId = video.content_ID;
            self.videoName = video.content_Name;
            self.courseID = -1; //distinguish from course
            self.viewerFlag = video.viewerFlag;
            self.memberID = video.memberID;
            self.video_Page = 1;
            self.$refs.videoPlay.GetCommentsForVideo(video.content_ID);
            self.$refs.videoPlay.getFavorite(video.favFlag);
          }
        });
    },
    ReadNotiForSoundComment(item) {
      let self = this;
      const request = {
        id: item.notificationTransactionID,
        videoID: item.id,
        userID: parseInt(localStorage.getItem("UserID")),
      };
      axios
        .post(
          `${self.web_url}NotificationTransaction/UpdateNotiForSoundComment`,
          request
        )
        .then(function (res) {
          if (res.data.status == 0) {
            console.log("ressound", res.data.data);
            self.soundPlay = res.data.data;
            const List = self.soundPlay.find((item) => item.id === item.id);
            self.showSound = true;
            self.audioImg = List.pictureBackground;
            self.audioName = List.content_Name;
            self.audioDescription = List.wordDescription;
            self.contentId = List.content_ID;
            self.viewerFlag = List.viewerFlag;
            self.dialog = true;
            self.contentFile = List.content_File;
            self.requestflag = List.requestFlag;
            self.$refs.soundPlay.getFavorite(List.favFlag);
            self.$refs.soundPlay.GetCommentsForSound(List.content_ID);
          }
        });
    },
    ReadNotiForArticleComment(item) {
      let self = this;
      const request = {
        id: item.notificationTransactionID,
        videoID: item.id,
        userID: parseInt(localStorage.getItem("UserID")),
      };
      axios
        .post(
          `${self.web_url}NotificationTransaction/UpdateNotiForArticleComment`,
          request
        )
        .then(function (res) {
          if (res.data.status == 0) {
            self.$router.push({
              path: "/Library/_subLibrary/_path/_article/articleView",
              query: { article_id: item.id },
            });
          }
        });
    },
    ReadNotiForKnowledgeComment(item) {
      let self = this;
      const request = {
        id: item.notificationTransactionID,
        videoID: item.id,
        userID: parseInt(localStorage.getItem("UserID")),
      };
      axios
        .post(
          `${self.web_url}NotificationTransaction/UpdateNotiForKnowledgeComment`,
          request
        )
        .then(function (res) {
          if (res.data.status == 0) {
            self.$router.push({
              path: "/LessonLearn/" + item.caseTitle.split("?").join(""),
              query: { lessonlearn_id: item.id },
            });
          }
        });
    },
    async getInboxNotificationData() {
      let self = this;

      const request = {
        userId: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
      };
      const res = await axios.post(
        `${this.web_urlV3}Notification/GetAllNotificationMobileV4`,
        request
      );
      if (res.data.status == 0) {
        console.log("res.data.datasssssArticle", res.data.data3);
        // Question and Answer
        let QnAList = res.data.data.map((v) => ({
          id: v.id,
          question: this.$t("LabelAnswerQuestion"),
          isCourse: false,
          sendDate: moment(new Date(v.sendDate + "z").toString()).format(
            "DD/MM/YYYY hh:mm A"
          ),
          contentType: 2,
          frameContainer: v.viewOrNot ? "#FFFFFF" : "#EFF7EA",
          newNoti: v.viewOrNot,
          createDate: moment(v.createDate),
        }));

        // Event
        let EventList = res.data.data1.map((v) => ({
          id: v.id,
          isCourse: true,
          contentType: 1,
          courseName: v.courseName,
          isCaseStudy: false,
          frameContainer: v.newNoti ? "#FFFFFF" : "#EFF7EA",
          question: self.CheckEventNoti(v),
          sendDate: moment(new Date(v.createDate + "z").toString()).format(
            "DD/MM/YYYY hh:mm A"
          ),
          createDate: moment(v.createDate),
          notificationTransactionID: v.notificationTransactionID,
          newNoti: v.newNoti,
          notiTitle: v.notiTitle,
        }));
        // Lesson Learn
        let LessonList = res.data.data2.map((v) => ({
          id: v.id,
          notificationTransactionID: v.notificationTransactionID,
          question: this.CheckLessonLearnNoti(v),
          createDate: moment(v.createDate),
          sendDate: moment(new Date(v.createDate + "z").toString()).format(
            "DD/MM/YYYY hh:mm A"
          ),
          isCourse: true,
          isCaseStudy: true,
          frameContainer: v.newNoti ? "#FFFFFF" : "#EFF7EA",
          caseImage: v.caseImage,
          contentType: 3,
          caseTitle: v.caseTitle,
          caseDescription: v.caseDescription,
          wordContent: v.wordContent,
          newNoti: v.newNoti,
          notiTitle: v.notiTitle,
        }));

        // Comment
        let commentList = res.data.data3.map((v) => ({
          id: v.id,
          notificationTransactionID: v.notificationTransactionID,
          question: this.CheckCommentNoti(v),
          createDate: moment(v.createDate),
          sendDate: moment(new Date(v.createDate + "z").toString()).format(
            "DD/MM/YYYY hh:mm A"
          ),
          frameContainer: v.newNoti ? "#FFFFFF" : "#EFF7EA",
          contentType: this.CheckContentTypeNoti(v.page),
          newNoti: v.newNoti,
          notiTitle: v.notiTitle,
          NotiType: v.notiType,
        }));
        // Article
        let ArticleList = res.data.data4.map((v) => ({
          sendDate: moment(new Date(v.createDate + "z").toString()).format(
            "DD/MM/YYYY hh:mm A"
          ),

          createDate: moment(v.createDate),
          id: v.id,
          question: this.CheckArticleNoti(v),
          notificationTransactionID: v.notificationTransactionID,
          frameContainer: v.newNoti ? "#FFFFFF" : "#EFF7EA",
          topicID: v.topicID,
          contentType: 5,
          musicName: v.contentName,
          wordDescription: v.wordDescription,
          picturebackground: v.picturebackground,
          wordContent: v.wordContentText,
          newNoti: v.newNoti,
          notiTitle: v.notiTitle,
        }));

        // Sound
        let SoundList = res.data.data5.map((v) => ({
          id: v.id,
          topicID: v.topicID,
          notificationID: v.notificationID,
          notificationTransactionID: v.notificationID,
          question: this.CheckSoundNoti(v),
          sendDate: moment(new Date(v.createDate + "z").toString()).format(
            "DD/MM/YYYY hh:mm A"
          ),
          createDate: moment(v.createDate),
          frameContainer: v.newNoti ? "#FFFFFF" : "#EFF7EA",
          contentType: 6,
          musicName: v.name,
          wordDescription: v.description,
          NotiType: v.notiType,
          ItemId: v.itemId,
          newNoti: v.newNoti,
          notiTitle: v.notiTitle,
        }));

        // Video
        let VideoList = res.data.data6.map((v) => ({
          id: v.id,
          topicID: v.topicID,
          notificationID: v.notificationID,
          notificationTransactionID: v.notificationID,
          question: this.CheckVideoNoti(v),
          sendDate: moment(new Date(v.createDate + "z").toString()).format(
            "DD/MM/YYYY hh:mm A"
          ),
          createDate: moment(v.createDate),
          frameContainer: v.newNoti ? "#FFFFFF" : "#EFF7EA",
          contentType: 7,
          musicName: v.name,
          wordDescription: v.description,
          NotiType: v.notiType,
          ItemId: v.itemId,
          newNoti: v.newNoti,
          notiTitle: v.notiTitle,
        }));

        // Assignment
        let AssignmentnotiList = res.data.data7.map((v) => ({
          id: v.id,
          notificationTransactionID: v.notificationTransactionID,
          topicID: v.assignmentCourseID,
          notificationID: v.notificationID,
          sendDate: moment(new Date(v.createDate + "z").toString()).format(
            "DD/MM/YYYY hh:mm A"
          ),
          createDate: moment(v.createDate),
          question: this.CheckAssignmentNoti(v),
          frameContainer: v.newNoti ? "#FFFFFF" : "#EFF7EA",
          contentType: 8,
          NotiType: v.notiType,
          newNoti: v.newNoti,
          notiTitle: v.notiTitle,
        }));

        // Quiz
        let QuizList = res.data.data8.map((v) => ({
          id: v.id,
          notificationTransactionID: v.notificationTransactionID,
          topicID: v.assignmentCourseID,
          notificationID: v.notificationID,
          sendDate: moment(new Date(v.createDate + "z").toString()).format(
            "DD/MM/YYYY hh:mm A"
          ),
          createDate: moment(v.createDate),
          question: this.CheckQuizNoti(v),
          frameContainer: v.newNoti ? "#FFFFFF" : "#EFF7EA",
          QuizName: v.quizName,
          contentType: 9,
          NotiType: v.notiType,
          newNoti: v.newNoti,
          notiTitle: v.notiTitle,
        }));
        this.inboxData = [
          ...QnAList,
          ...EventList,
          ...LessonList,
          ...ArticleList,
          ...SoundList,
          ...VideoList,
          ...AssignmentnotiList,
          ...QuizList,
          ...commentList,
        ];

        this.inboxData.sort(function (a, b) {
          let key1 = b.createDate;
          let key2 = a.createDate;

          if (key1 < key2) {
            return -1;
          } else if (key1 == key2) {
            return 0;
          } else {
            return 1;
          }
        });
        this.loading = false;
      }
    },
    ReadNoti(notificationid, pageid) {
      let self = this;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        notificationID: notificationid,
        notiPage: pageid,
      };
      console.log("request", request);
      axios
        .post(`${self.web_url}Notification/AddUserIDinNotiJoin`, request)
        .then(function (result) {
          if (result.data.status == 0) {
            self.getNotificationCountInbox();
          }
        });
    },
    QnaNoti(id) {
      let self = this;
      const request = {
        id: id,
      };
      axios.post(`${self.web_url}QNA/ViewQnA`, request).then(function (result) {
        if (result.data.status == 0) {
          self.getNotificationCountInbox();
        }
      });
    },
    async getNotificationCountInbox() {
      let self = this;
      const request = {
        userId: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
      };
      await axios
        .post(
          `${self.web_urlV3}Notification/GetAllNotificationMobileV4`,
          request
        )
        .then(function (result) {
          if (result.data.status == 0) {
            let d1 = result.data.data.filter(
              (x) => x.viewOrNot === false
            ).length;
            let d2 = result.data.data1.filter(
              (x) => x.newNoti === false
            ).length;
            let d3 = result.data.data2.filter(
              (x) => x.newNoti === false
            ).length;
            let d4 = result.data.data4.filter(
              (x) => x.newNoti === false
            ).length;
            let d5 = result.data.data5.filter(
              (x) => x.newNoti === false
            ).length;
            let d6 = result.data.data6.filter(
              (x) => x.newNoti === false
            ).length;
            let d7 = result.data.data7.filter(
              (x) => x.newNoti === false
            ).length;
            let d8 = result.data.data8.filter(
              (x) => x.newNoti === false
            ).length;
            let d9 = result.data.data3.filter(
              (x) => x.newNoti === false
            ).length;
            let notiCount = d1 + d2 + d3 + d4 + d5 + d6 + d7 + d8 + d9;
            self.$store.commit("savenotiCount", notiCount);
          }
        });
    },
    CheckContentTypeNoti(page) {
      let returndata = 0;
      switch (page) {
        case 1:
          returndata = 10;
          break;
        case 2:
          returndata = 11;
          break;
        case 3:
          returndata = 12;
          break;
        case 4:
          returndata = 13;
          break;
        case 5:
          returndata = 14;
          break;
        default:
          returndata = 15;
          break;
      }
      return returndata;
    },
    CheckCommentNoti(item) {
      let message1 = "";
      let message2 = "";
      let notimessage = "";
      if (item.notiTitle == 29) {
        message1 = this.$t("lblvideocommentmessage").replace(
          "{0}",
          item.fullname
        );
        notimessage = message1.replace("{1}", item.videoName);
      } else {
        message1 = this.$t("lblcoursecommentmessage").replace(
          "{0}",
          item.fullname
        );
        message2 = message1.replace("{1}", item.videoName);
        notimessage = message2.replace("{2}", item.courseName);
      }
      return notimessage;
    },
    CheckEventNoti(item) {
      let Notimsg = "";
      let eventstartdateshow = moment(item.startDate)
        .local()
        .format("DD/MM/YYYY");
      let eventenddateshow = moment(item.endDate).local().format("DD/MM/YYYY");
      let msg27 = "";
      let msgs27 = "";
      let msgd = "";
      let msgsd = "";
      switch (item.notiTitle) {
        case 27:
          msg27 = this.$t("lblenrollevent").replace("{0}", item.courseName);
          msgs27 = msg27.replace("{1}", eventstartdateshow);
          Notimsg = msgs27.replace("{2}", eventenddateshow);
          break;
        case 28:
          Notimsg = this.$t("lblenrollremoveevent").replace(
            "{0}",
            item.courseName
          );
          break;
        default:
          msgd = this.$t("lbladdupdateevent").replace("{0}", item.courseName);
          msgsd = msgd.replace("{1}", eventstartdateshow);
          Notimsg = msgsd.replace("{2}", item.courseLocation);
          break;
      }
      return Notimsg;
    },
    CheckLessonLearnNoti(item) {
      let Notimsg = "";
      let msgs18 = "";
      let msgs22 = "";
      let msgs23 = "";
      switch (item.notiTitle) {
        case 12:
          Notimsg = this.$t("lblupdateknowledgesharing").replace(
            "{0}",
            item.caseTitle
          );
          break;
        case 18:
          msgs18 = this.$t("lblknowledgesharingrequestinbox").replace(
            "{0}",
            item.caseTitle
          );
          Notimsg = msgs18.replace("{1}", item.expireDate);
          break;
        case 22:
          msgs22 = this.$t("lblnearlyknowledgesharinginbox").replace(
            "{0}",
            item.caseTitle
          );
          Notimsg = msgs22.replace("{1}", item.endDate);
          break;
        case 23:
          msgs23 = this.$t("lblbeforeknowledgesharinginbox").replace(
            "{0}",
            item.caseTitle
          );
          Notimsg = msgs23.replace("{1}", item.expireDate);
          break;
        default:
          Notimsg = this.$t("lblknowledgesharingnotrequestinbox").replace(
            "{0}",
            item.caseTitle
          );
          break;
      }
      return Notimsg;
    },
    CheckArticleNoti(item) {
      let Notimsg = "";
      let msg9 = "";
      let msgs9 = "";
      let expdate16 = "";
      let msg16 = "";
      let msgs16 = "";
      let msgss16 = "";
      let enddate20 = "";
      let msg20 = "";
      let msgs20 = "";
      let msgss20 = "";
      let expdate21 = "";
      let msg21 = "";
      let msgs21 = "";
      let msgss21 = "";
      let msgde = "";
      let msgsde = "";
      switch (item.notiTitle) {
        case 9:
          msg9 = this.$t("lblUpdatelibraryinbox").replace(
            "{0}",
            this.$t("article")
          );
          msgs9 = msg9.replace("{1}", item.contentName);
          Notimsg = msgs9.replace("{2}", item.topicName);
          break;
        case 16:
          expdate16 = moment(item.expireDate).local().format("DD/MM/YYYY");
          msg16 = this.$t("lblcategoryrequestinbox").replace(
            "{0}",
            this.$t("article")
          );
          msgs16 = msg16.replace("{1}", item.contentName);
          msgss16 = msgs16.replace("{2}", item.topicName);
          Notimsg = msgss16.replace("{3}", expdate16);
          break;
        case 20:
          enddate20 = moment(item.endDate).local().format("DD/MM/YYYY");
          msg20 = this.$t("lblnearlylibraryinbox").replace(
            "{0}",
            this.$t("article")
          );
          msgs20 = msg20.replace("{1}", item.contentName);
          msgss20 = msgs20.replace("{2}", item.topicName);
          Notimsg = msgss20.replace("{3}", enddate20);
          break;
        case 21:
          expdate21 = moment(item.expireDate).local().format("DD/MM/YYYY");
          msg21 = this.$t("lblbeforelibraryinbox").replace(
            "{0}",
            this.$t("article")
          );
          msgs21 = msg21.replace("{1}", item.contentName);
          msgss21 = msgs21.replace("{2}", item.topicName);
          Notimsg = msgss21.replace("{3}", expdate21);
          break;
        default:
          msgde = this.$t("lblcategorynotrequestinbox").replace(
            "{0}",
            this.$t("article")
          );
          msgsde = msgde.replace("{1}", item.contentName);
          Notimsg = msgsde.replace("{2}", item.topicName);
          break;
      }
      return Notimsg;
    },
    CheckSoundNoti(item) {
      let Notimsg = "";
      let msg10 = "";
      let msgs10 = "";
      let msgss10 = "";
      let expdate16 = "";
      let msg16 = "";
      let msgs16 = "";
      let msgss16 = "";
      let msgsss16 = "";
      let enddate20 = "";
      let msg20 = "";
      let msgs20 = "";
      let msgss20 = "";
      let msgsss20 = "";
      let expdate21 = "";
      let msg21 = "";
      let msgs21 = "";
      let msgss21 = "";
      let msgsss21 = "";
      let msgde = "";
      let msgsde = "";
      let msgssde = "";
      switch (item.notiTitle) {
        case 10:
          msg10 = this.$t("lblUpdatelibraryinboxsoundvideo").replace(
            "{0}",
            this.$t("sound")
          );
          msgs10 = msg10.replace("{1}", item.name);
          msgss10 = msgs10.replace("{2}", item.titleName);
          Notimsg = msgss10.replace("{3}", item.topicName);
          break;
        case 16:
          expdate16 = moment(item.expireDate).local().format("DD/MM/YYYY");
          msg16 = this.$t("lblcategoryrequestinboxsoundvideo").replace(
            "{0}",
            this.$t("sound")
          );
          msgs16 = msg16.replace("{1}", item.name);
          msgss16 = msgs16.replace("{2}", item.titleName);
          msgsss16 = msgss16.replace("{3}", item.topicName);
          Notimsg = msgsss16.replace("{4}", expdate16);
          break;
        case 20:
          enddate20 = moment(item.endDate).local().format("DD/MM/YYYY");
          msg20 = this.$t("lblnearlylibraryinboxsoundvideo").replace(
            "{0}",
            this.$t("sound")
          );
          msgs20 = msg20.replace("{1}", item.name);
          msgss20 = msgs20.replace("{2}", item.titleName);
          msgsss20 = msgss20.replace("{3}", item.topicName);
          Notimsg = msgsss20.replace("{4}", enddate20);
          break;
        case 21:
          expdate21 = moment(item.expireDate).local().format("DD/MM/YYYY");
          msg21 = this.$t("lblbeforelibraryinboxsoundvideo").replace(
            "{0}",
            this.$t("sound")
          );
          msgs21 = msg21.replace("{1}", item.name);
          msgss21 = msgs21.replace("{2}", item.titleName);
          msgsss21 = msgss21.replace("{3}", item.topicName);
          Notimsg = msgsss21.replace("{4}", expdate21);
          break;
        default:
          msgde = this.$t("lblcategorynotrequestinboxsoundvideo").replace(
            "{0}",
            this.$t("sound")
          );
          msgsde = msgde.replace("{1}", item.name);
          msgssde = msgsde.replace("{2}", item.titleName);
          Notimsg = msgssde.replace("{3}", item.topicName);
          break;
      }
      return Notimsg;
    },
    CheckVideoNoti(item) {
      let Notimsg = "";
      let msg11 = "";
      let msgs11 = "";
      let msgss11 = "";
      let expdate16 = "";
      let msg16 = "";
      let msgs16 = "";
      let msgss16 = "";
      let msgsss16 = "";
      let enddate20 = "";
      let msg20 = "";
      let msgs20 = "";
      let msgss20 = "";
      let msgsss20 = "";
      let expdate21 = "";
      let msg21 = "";
      let msgs21 = "";
      let msgss21 = "";
      let msgsss21 = "";
      let msgde = "";
      let msgsde = "";
      let msgssde = "";
      switch (item.notiTitle) {
        case 11:
          msg11 = this.$t("lblUpdatelibraryinboxsoundvideo").replace(
            "{0}",
            this.$t("video")
          );
          msgs11 = msg11.replace("{1}", item.name);
          msgss11 = msgs11.replace("{2}", item.titleName);
          Notimsg = msgss11.replace("{3}", item.topicName);
          break;
        case 16:
          expdate16 = moment(item.expireDate).local().format("DD/MM/YYYY");
          msg16 = this.$t("lblcategoryrequestinboxsoundvideo").replace(
            "{0}",
            this.$t("video")
          );
          msgs16 = msg16.replace("{1}", item.name);
          msgss16 = msgs16.replace("{2}", item.titleName);
          msgsss16 = msgss16.replace("{3}", item.topicName);
          Notimsg = msgsss16.replace("{4}", expdate16);
          break;
        case 20:
          enddate20 = moment(item.endDate).local().format("DD/MM/YYYY");
          msg20 = this.$t("lblnearlylibraryinboxsoundvideo").replace(
            "{0}",
            this.$t("video")
          );
          msgs20 = msg20.replace("{1}", item.name);
          msgss20 = msgs20.replace("{2}", item.titleName);
          msgsss20 = msgss20.replace("{3}", item.topicName);
          Notimsg = msgsss20.replace("{4}", enddate20);
          break;
        case 21:
          expdate21 = moment(item.expireDate).local().format("DD/MM/YYYY");
          msg21 = this.$t("lblbeforelibraryinboxsoundvideo").replace(
            "{0}",
            this.$t("video")
          );
          msgs21 = msg21.replace("{1}", item.name);
          msgss21 = msgs21.replace("{2}", item.titleName);
          msgsss21 = msgss21.replace("{3}", item.topicName);
          Notimsg = msgsss21.replace("{4}", expdate21);
          break;
        default:
          msgde = this.$t("lblcategorynotrequestinboxsoundvideo").replace(
            "{0}",
            this.$t("video")
          );
          msgsde = msgde.replace("{1}", item.name);
          msgssde = msgsde.replace("{2}", item.titleName);
          Notimsg = msgssde.replace("{3}", item.topicName);
          break;
      }
      return Notimsg;
    },
    CheckAssignmentNoti(item) {
      let Notimsg = "";
      let eventenddatetoshow = moment(item.EndDate)
        .local()
        .format("DD/MM/YYYY");
      let msg14 = "";
      let msg24 = "";
      let msg19 = "";
      switch (item.notiTitle) {
        case 14:
          msg14 = this.$t("lblUpdateAssignmentinbox").replace(
            "{0}",
            item.courseName
          );
          Notimsg = msg14.replace("{1}", eventenddatetoshow);
          break;
        case 24:
          msg24 = this.$t("lblnearlyacinbox").replace("{0}", item.courseName);
          Notimsg = msg24.replace("{1}", eventenddatetoshow);
          break;
        case 19:
          msg19 = this.$t("lblassignmentcourseinbox").replace(
            "{0}",
            item.courseName
          );
          Notimsg = msg19.replace("{1}", eventenddatetoshow);
          break;
        default:
          Notimsg = this.$t("lblCreateAssignmentinbox").replace(
            "{0}",
            item.courseName
          );
          break;
      }
      return Notimsg;
    },
    CheckQuizNoti(item) {
      let Notimsg = "";
      let eventenddatetoshow = moment(item.EndDate)
        .local()
        .format("DD/MM/YYYY");
      if (item.notiTitle === 29) {
        let msg = this.$t("lblassignmentcoursequizinbox").replace(
          "{0}",
          item.quizName
        );
        let msgs = msg.replace("{1}", item.courseName);
        Notimsg = msgs.replace("{2}", eventenddatetoshow);
      } else {
        Notimsg = this.$t("lblCreateAssignmentinbox").replace(
          "{0}",
          item.courseName
        );
      }

      return Notimsg;
    },
  },
};
</script>

<style scoped>
::v-deep .pagination .primary {
  background-color: #e8e9ec !important;
  color: #4d4f5c !important;
  opacity: 1;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background-color: unset;
}
.inboxcard {
  height: 120px;
}
</style>

<style scoped>
.truncated-text-2line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  white-space: pre-line;
}
</style>
